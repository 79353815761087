import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <InlineNotification mdxType="InlineNotification">
      <p>{`Legion-Android SDK currently is available as a private gitlab registry only for internal products telkom indonesia development.`}</p>
    </InlineNotification>
    <br />
    <h2>{`Prequisites`}</h2>
    <p>{`If you want clone this project or install SDK in your project, please make sure you Already have at least these below:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Android Studio`}</strong>{`: Flamigo version `}<strong parentName="li"><em parentName="strong">{`2022.2.1`}</em></strong>{` or higher`}</li>
      <li parentName="ul"><strong parentName="li">{`Gradle Version: 8.0`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`JDK`}</strong>{`: Java Development Kit 17 or higher`}</li>
      <li parentName="ul"><strong parentName="li">{`Terminal`}</strong>{`: If you using `}<strong parentName="li">{`Mac`}</strong>{` or `}<strong parentName="li">{`Linux`}</strong>{` you can use native terminal, but if you using `}<strong parentName="li">{`Windows`}</strong>{` you can use `}<a parentName="li" {...{
          "href": "https://git-scm.com/downloads"
        }}>{`Gitbash`}</a>{` or `}<a parentName="li" {...{
          "href": "https://hyper.is/#installation"
        }}>{`Hyper`}</a>{` or another terminal that can run bash scripts`}</li>
      <li parentName="ul"><strong parentName="li">{`Git Client`}</strong>{`: If you using `}<strong parentName="li">{`Mac`}</strong>{` or `}<strong parentName="li">{`Linux`}</strong>{` you can use embaded git client, but if you using `}<strong parentName="li">{`Windows`}</strong>{` you can use `}<a parentName="li" {...{
          "href": "https://git-scm.com/downloads"
        }}>{`Gitbash`}</a></li>
      <li parentName="ul"><strong parentName="li">{`Android Version`}</strong>{` : Android Lollipop 5.1.1 SDK 22 or higher`}</li>
    </ul>
    <div className="divi" />
    <h2>{`Install Legion SDK to Your Project`}</h2>
    <h3><strong parentName="h3">{`Proxy and Authentication`}</strong></h3>
    <ol>
      <li parentName="ol">{`Open your global `}<inlineCode parentName="li">{`gradle.properties`}</inlineCode>{` directory on with `}<strong parentName="li"><em parentName="strong">{`Terminal`}</em></strong>{`:`}</li>
    </ol>
    <p>{`For `}<strong parentName="p">{`Windows`}</strong>{` : `}<inlineCode parentName="p">{`C:\\Users\\<you>\\.gradle\\gradle.properties`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`cd C:\\Users\\<you>\\.gradle
`}</code></pre>
    <p>{`For `}<strong parentName="p">{`Mac/Linux`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`/Users/<you>/.gradle/gradle.properties`}</inlineCode>{` or`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`/home/<you>/.gradle/gradle.properties`}</inlineCode>{` or`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`~/.gradle/gradle.properties`}</inlineCode>{` `}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`cd ~/.gradle
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Create global `}<inlineCode parentName="li">{`gradle.properties`}</inlineCode>{`, If you already have it, you can skip this step`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`touch gradle.properties
`}</code></pre>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Add Credentials to global `}<inlineCode parentName="li">{`gradle.properties`}</inlineCode>{`. `}<strong parentName="li">{`Credentials Placeholder`}</strong>{` : as on how to get the credentials, please fill in following `}<a parentName="li" {...{
          "href": "https://docs.google.com/forms/d/e/1FAIpQLSfhy96X4qwOJyBEo9-g0oLO9NaKNF8uqxGLfcoL5rKxKASqSg/viewform"
        }}>{`form`}</a>{` to gain access for all the necessary things you need, after you receive it you can replace the placeholder ?? with gained credentials.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`echo "legion_username=??" >> gradle.properties && echo "legion_password=??" >> gradle.properties
`}</code></pre>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Add Legion Gitlab Registry on Gradle`}</li>
    </ol>
    <p><strong parentName="p">{`Gradle Kotlin DSL`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`dependencyResolutionManagement {
    repositoriesMode.set(RepositoriesMode.FAIL_ON_PROJECT_REPOS)
    repositories {
        google()
        mavenCentral()
        maven { setUrl("https://jitpack.io"/)  }
        maven {
            name = "Legion"
            setUrl("legion_repository")
            credentials {
                username = settings.extensions.extraProperties.get("legion_username") as String
                password = settings.extensions.extraProperties.get("legion_password") as String
            }
            authentication {
                create("Basic", BasicAuthentication::class.java)
            }
        }
    }
}
`}</code></pre>
    <p>{`Note: Placeholders subject to change are `}<inlineCode parentName="p">{`legion_repository`}</inlineCode>{`, `}<inlineCode parentName="p">{`legion_username`}</inlineCode>{`, and `}<inlineCode parentName="p">{`legion_password`}</inlineCode>{` which you could get by requesting its access via our `}<a parentName="p" {...{
        "href": "https://forms.gle/vNvoJ5t6FDM7D1VD8"
      }}>{`submission form`}</a>{`.`}</p>
    <h3><strong parentName="h3">{`Install or Add Dependency`}</strong></h3>
    <ol>
      <li parentName="ol">{`Add UI Kit Dependency on `}<strong parentName="li">{`build.gradle`}</strong>{` `}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`dependencies {
    implementation(platform('com.telkom.legion:android-platform:2.0.0'))
    implementation('com.telkom.legion:android-component')
    implementation('com.telkom.legion:android-extension')
}
`}</code></pre>
    <p>{`Additional installation if you plan to use Compose to put on the `}<strong parentName="p">{`build.gradle`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`dependencies {
    implementation("com.telkom.legion.compose:android-compose-platform:2.0.0")
    implementation("com.telkom.legion.compose:android-compose-component:2.0.0")
}
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Add Jetifier Support on Project `}<strong parentName="li">{`gradle.properties`}</strong></li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`android.enableJetifier = true;
`}</code></pre>
    <h3><strong parentName="h3">{`Run Prototype Project`}</strong></h3>
    <p>{`If you want runing prototype project, We very recomended working git using ssh instead of https`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`git clone ssh://git@gitlab.playcourt.id:31022/mobileteam/legion-android.git
// Open Legion Project Dir
cd legion-android
// Start Compile APK Prototype
./gradlew assembleDebug
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      